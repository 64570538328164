import React, { useEffect, useState, useContext } from "react"
import {Helmet} from "react-helmet";
import { map } from "lodash"
import { Card, CardBody, CardTitle, Col, Container, Row, Table, Spinner } from "reactstrap"
import AddClientToUser from "./addClientToUser";
import Breadcrumbs from "components/Common/Breadcrumbclientdetails"
import MiniCards from "../../components/client/mini-card"
import { useLocation, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import { AuthContext } from "context/authContext";
import {commaSeperatedNumberWithoutDecimal} from "helpers/stringHelper"
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const ClientDetails = () => {
	const { userData } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [addMoreOpen, setAddMoreOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [id, setId] = useState('');
  const [columns,setColumns] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingClients, setLoadingClients] = useState(true);
  const [moduleList, setModuleList] = useState([])
  const [miniCards, setMiniCards] = useState([
    { title: "User type", iconClass: "bx-id-card", text: "Customer" },
    { title: "Total Clients", iconClass: "bx-user-circle", text: "31" },
  ]);
  const [leaseByClient, setLeaseByClient] = useState([]);

  const unChangedColumns = [
    {
      name: "client",
      label: "Client",
      options: {
        setCellProps: () => ({style: {minWidth: '150px',height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
        customBodyRenderLite:(dataIndex)=>(
          <>
            {(clientData && (!clientData[dataIndex] || !clientData[dataIndex].client_id || !clientData[dataIndex].client_id.logo)) ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <div className="avatar-title rounded-circle">
                    {clientData && clientData[dataIndex] && clientData[dataIndex].client_id && clientData[dataIndex].client_id.client.charAt(0)}
                  </div>
                </div>
                <div className="client">
                  <div>{clientData && clientData[dataIndex] && clientData[dataIndex].client_id && clientData[dataIndex].client_id.client}</div>
                </div>
              </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="avatar-sm">
                    <img
                      className="rounded-circle avatar-sm"
                      src={clientData[dataIndex].client_id.logo}
                      alt=""
                    />
                  </div>
                  <div className="client">
                    <div>{clientData[dataIndex].client_id.client}</div>
                  </div>
                </div>
              )}
          </>
        ),
      }
    },
    {
      name: "no_of_leases",
      label: "No Of Leases",
      options: {
        customBodyRenderLite:(dataIndex)=>{
          const clientLeases = leaseByClient && leaseByClient[0] ? leaseByClient.filter(v => String(v._id) == String(clientData && clientData[dataIndex] && clientData[dataIndex].client_id && clientData[dataIndex].client_id._id)) : []
          const no_of_leases = clientLeases && clientLeases [0] ? Number(clientLeases[0].activeLeases || 0) + ((Number(clientLeases[0].terminatedLeases || 0) + Number(clientLeases[0].expiredLeases || 0) + Number(clientLeases[0].draftedLeases || 0))/4) : 0
          return <div 
            className="table-data-margin"
            tabIndex="0" dataToggle="tooltip" 
            title={`Active Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].activeLeases || 0) : 0} \nTerminated Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].terminatedLeases || 0) : 0} \nInactive Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].expiredLeases || 0) : 0} \nDrafted Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].draftedLeases || 0) : 0}`}
          >
            {no_of_leases}
          </div>
        }
      }
    },
    {
      name: "assignement_date",
      label: "Assigned Since",
      options: {
        customBodyRender:(user)=> (<div className="table-data-margin">{moment.utc(user).format('MM/DD/YYYY HH:mm')}</div>)
      }
    },
    {
      name: "access_type",
      label: "Access Type",
      options: {
        customBodyRenderLite:(dataIndex)=>(
          <div className="table-data-margin">
            <select
              id="formrow-InputState"
              className={`drp text-${clientData && clientData[dataIndex] && clientData[dataIndex].access_type === 'full_access' ? 'success' : 'warning'}`}
              style={{ border: 'none', width: 'auto',fontWeight:'bold' }}
              onChange={(e) => handleAccessTypeChange(e, (clientData && clientData[dataIndex]))}
            >
              <option name="active" value="read_only" selected={clientData && clientData[dataIndex] && clientData[dataIndex].access_type === 'read_only'}>READ ONLY</option>
              <option name="disable" value="full_access" selected={clientData && clientData[dataIndex] && clientData[dataIndex].access_type === 'full_access'}>FULL ACCESS</option>
              <option name="disable" value="remove_access">REMOVE ACCESS</option>
            </select>
          </div>
        )
      }
    }
  ]

  const getModules = async() =>{
    try{
        const {data} = await axios.post(`${AppConfig.baseUrl}/module/getCustomerModules`,{},{
            headers: { token: localStorage.getItem("token") }
        })
        if(!data.error){
            setModuleList(data.data)
        }else{
            throw new Error(data.title);
        }
    }catch(error){
        toast.error(error.message || "something went wrong");

    }
  }

  useEffect(() => {
    const moduleData = moduleList.filter(m => m.tag == 'audit_category')
    if (moduleData && moduleData[0]) {
      setColumns([...unChangedColumns,
        {
          name: "can_review",
          label: "Lease review",
          options: {
            customBodyRenderLite:(dataIndex)=>(
              <div className="d-flex mb-0">
                {
                  clientData && clientData[dataIndex] && clientData[dataIndex].client_id && clientData[dataIndex].client_id.modules && clientData[dataIndex].client_id.modules.includes('audit_category') ?
                    <div className="form-check form-switch form-switch-md mt-2 ms-3">
                        <input
                            type="checkbox"
                            className="form-check-input switch-icon-color"
                            id="can_review"
                            name="can_review"
                            checked = {clientData[dataIndex].can_review || false}
                            onChange={(e) => {
                              changeCanReview(clientData[dataIndex]._id,e.target.checked,(clientData[dataIndex] && clientData[dataIndex].user_id &&clientData[dataIndex].user_id._id))
                            }}
                        />
                    </div>
                  : <div className="table-data-margin ms-3"> NA</div>
                }                
              </div>
            )
          }
        }
      ])
    }
  }, [moduleList])

  useEffect(() => {
    if(userData.id){
      setColumns(unChangedColumns)
    }
  }, [userData, clientData, leaseByClient])

  useEffect(() => {
    setId((location.search).replace('?', ''));
    setColumns(unChangedColumns)
  }, [])

  useEffect(() => {
    if(id){
      getUserDetails(id);
    }
    getModules();
  },[id])

  const changeCanReview = async (userclientid,review,userid)=>{
    setLoading(true)
    try {
        const {data} = await axios.post(`${AppConfig.baseUrl}/user_client/updateReview`,{id:userclientid,review:review},{
          headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          toast.success("client review updated successfully");
          getUserDetails(userid)
        }
        else{
          throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
        setLoading(false)
    }
  }

  const getUserDetails = async (user_id) => {
      setLoading(true)
      try {
          const {data} = await axios.get(`${AppConfig.baseUrl}/customer/userdetails?id=${user_id}`,{
            headers: { token: localStorage.getItem("token") }
          });
          if(!data.error){
            setUserDetails(data.userDetails)
          }
          else{            
            toast.error(data.title);
            navigate(`/${localStorage.getItem('company')}/users`)
          }
      } catch (error) {
          toast.error(error.message || 'Something went wrong');
      }
      setLoading(false)
  }

  const getClients = async () => {
    setLoadingClients(true)
    setClientData([])
    setLeaseByClient([])
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userDetails._id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
            setLeaseByClient(data.allLeaseDataByClient || [])
            setClientData(data.clientData || [])
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoadingClients(false)
  }

  useEffect(() => {
      if(userDetails && userDetails._id){
        getClients();
      }
  }, [userDetails])

  useEffect(() => {
      setMiniCards([
        { title: "User type", iconClass: "bx-id-card", text: userDetails.type ? `${userDetails && userDetails.type === 'user' ? `${userData && userData.company ? userData.company.toUpperCase() : ''} `: ''}${userDetails && userDetails.type ? userDetails.type.replace("_", " ").toUpperCase() : 'NA'}` : '' },
        { title: "Total Clients", iconClass: "bx-user-circle", text: userDetails.no_of_clients ? commaSeperatedNumberWithoutDecimal(userDetails.no_of_clients) : 0 },
      ])
  },[userDetails])

  const toggleAddMore = () => {
    setAddMoreOpen(!addMoreOpen);
  }

  const handleStatusChange = async(e, user) =>{
    setLoading(true)
    try {
      const {data} = await axios.post(`${AppConfig.baseUrl}/users/change_status`, {id: user._id, is_active: e.target.value},{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        toast.success("User status changed successfully");
        getUserDetails(id || user._id)
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
      setLoading(false)
    }
  }

  const handleAccessTypeChange = async(e, user) =>{
    setLoading(true)
    try {
      let mainData ={}
      if(e.target.value === 'remove_access'){
        const {data} = await axios.post(`${AppConfig.baseUrl}/user_client/remove_access`, {user_id: user.user_id._id, client_id: user.client_id._id},{
          headers: { token: localStorage.getItem("token") }
        });
        mainData = data;
      }else{
        const {data} = await axios.post(`${AppConfig.baseUrl}/user_client/change_access_type`, {id: user._id, access_type: e.target.value},{
          headers: { token: localStorage.getItem("token") }
        });
        mainData = data;
      }

      if(!mainData.error){
        toast.success(mainData.title || 'Client operation successful');
        getUserDetails(id || user.user_id._id)
      }
      else{
        throw new Error(mainData.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
      setLoading(false)
    }
  }

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseJava | Users Details</title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs title="clientdetail" breadcrumbItem="" name={userDetails.name} image={userDetails.profile_picture} type="userdetails"/>
          {
            loading ?
              <div style={{textAlign: 'center'}}><Spinner color="primary" className="txt-theme-color" /></div>
            :
            <Row>
              <Col xl="4" lg="4" md="4" xxl="4">
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle className="mb-4">OVERVIEW</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Name :</th>
                            <td>{userDetails.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Email :</th>
                            <td>{userDetails.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Status:</th>
                            <td>
                              <div>
                                <select
                                  id="formrow-InputState"
                                  className={`drp text-${userDetails.is_active ? 'success' : 'warning'}`}
                                  style={{ border: 'none', width: 'auto',fontWeight:'bold' }}
                                  onChange={(e) => handleStatusChange(e, userDetails)}
                                  disabled={userDetails.email == userData.email || userDetails.email == userData.user_email}
                                >
                                  <option name="active" value={true} selected={userDetails.is_active}>ACTIVE</option>
                                  <option name="disable" value={false} selected={!userDetails.is_active}>DISABLED</option>
                                </select>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">User since:</th>
                            <td>{moment.utc(userDetails.added_on).format('MM/DD/YYYY')}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="8" lg="8" md="8" xxl="8">
                <Row>
                  {map(miniCards, (card, key) => (
                    <MiniCards
                      title={card.title}
                      text={card.text}
                      iconClass={card.iconClass}
                      key={"_card_" + key}
                    />
                  ))}
                </Row>
                <Card>
                  <CardBody>
                      <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                      MUIDataTableToolbar:{
                          styleOverrides:{
                          actions: {
                              display: 'contents'
                          }
                          }
                      }
                      }})}>
                      <MUIDataTable
                        title={
                          <Row>
                            <Col className="mt-2">CLIENTS</Col>
                            <Col>
                              <div className=" d-flex dataTables_filter">
                                <button
                                  type="button"
                                  className="btn btn-lbusers dataTables_filter"
                                  onClick={toggleAddMore}
                                >ADD MORE
                                </button>&nbsp;
                              </div>
                            </Col>
                          </Row>
                          }
                        data={clientData}
                        columns={columns}
                        key={Math.random()}
                        options={{ 
                          rowsPerPage:20, 
                          print:false, 
                          download:false, 
                          filter:false, 
                          selectableRows:"none", 
                          search:false, 
                          rowsPerPageOptions:[], 
                          responsive:'scroll',
                          textLabels: {
                            body: {
                              noMatch: loadingClients ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                            },
                            viewColumns: {
                              title: "",
                            },
                          } ,
                          downloadOptions:{
                            filename:'users.csv'
                          }
                        }}
                      />
                    </ThemeProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      <AddClientToUser addOpen={addMoreOpen} toggleAdd={toggleAddMore} setOpenPopup={setAddMoreOpen} user={userDetails} getUserDetails={getUserDetails} />
    </Layout>
  )
}


export default ClientDetails