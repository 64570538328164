import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Modal, Label, Input } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import axios from "axios";
import AppConfig from "../../constants/config";
import avatar from "../../assets/images/users/avatar-1.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext";
import CropImage from "components/Common/CropImage";

const EditUser = (prop) => {
	const { userData } = useContext(AuthContext);
    const { editOpen, toggleEdit, setEditOpen, user, getUserData, setSelectedUser } = prop;
    const [clientData, setClientData] = useState([]);
    const [image, setImage] = useState(avatar);
    const [cropOpen, setCropOpen] = useState(false)
    const [loading, setLoading] = useState(false);

    const handleSubmit = async(values) => {
        setLoading(true)
        try {
            values.id = user._id;
            values.profile_picture = image !== avatar ? image : '';
            const {data} = await axios.post(`${AppConfig.baseUrl}/users/edit_user`, values,{
                headers:{token: localStorage.getItem('token')}
            });
            if(!data.error){
                formik.resetForm();
                toast.success('User edited successfully');
                setSelectedUser({});
                getUserData();
                setEditOpen(false);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            setSelectedUser({});
            setEditOpen(false);
        }
        setLoading(false)
    }
  
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            name: '',
            type: 'user',
            access_type: 'full_access',
            client_id: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Please Enter Valid Email").required("Please Enter Your Email"),
            name: Yup.string().required("Please Enter Your Name"),
        }),
        onSubmit: handleSubmit
    });

    const handleChange = async(e, key) => {
        formik.setFieldValue(key, e.target.value)
    }

    const getUserClient = async() =>{
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${user._id}`,{
                headers:{token: localStorage.getItem('token')}
            });
            if(!data.error){
                setClientData(data.clientData || [])
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
    }

    const onSelectFile = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            setCropOpen(true);
        };
        reader.readAsDataURL(files[0]);
    };

    useEffect(() => {
        if(user && user._id){
            getUserClient();
        }
    }, [user])

    useEffect(() => {
        formik.setValues({
            email: user.email,
            name: user.name,
            type: user.type,
            access_type: user.access_type || 'full_access',
            client_id: clientData && clientData[0] && clientData[0].client_id ? clientData[0].client_id._id : '',
        })
        setImage(user.profile_picture || avatar);
    }, [user, clientData])

    return (
        <Modal
            isOpen={editOpen}
            toggle={() => {
                toggleEdit();
                setSelectedUser({});
            }}
            size="md"
            centered
        >
        <React.Fragment>
            <div className="modal-header">
                <h5 className="modal-title">Edit User</h5>
                <button
                    type="button"
                    onClick={() => {
                        setEditOpen(false);
                        setSelectedUser({});
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="image-area mt-3 mb-3">
                                <label style={{width: '100px', height:'100px', cursor:'pointer'}}>
                                    <input type="file" multiple={false} onChange={onSelectFile} style={{display:'none'}} />
                                        <img src={image} alt="" />
                                </label>
                                {
                                    image !== avatar && 
                                    <i className='bx bx-x remove-image' style={{display: 'inline-block'}} onClick={() => setImage(avatar)}></i>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-3">
                                <label className="col-form-label">Type:</label>
                                <select
                                    className="form-control arrows" 
                                    id="clientDataList" 
                                    type='select' 
                                    name="type"
                                    onChange={(e) => {
                                        handleChange(e, "type")
                                        formik.handleChange(e)
                                    }}
                                    onBlur={() => formik.setFieldTouched("type")}
                                >
                                    <option name="user" value="user" selected={formik.values.type === "user"}>{userData.company} User</option>
                                    <option name="third_party" value="third_party" selected={formik.values.type === "third_party"}>{"Third party user"}</option>
                                    <option name="admin" value="admin" selected={formik.values.type === "admin"}>Admin</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-2">
                                <Label for="name" className="col-form-label">
                                    Name:
                                </Label>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name || ""}
                                />
                                {formik.touched.name && formik.errors.name &&
                                    <div className="text-danger">{formik.errors.name}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-3 mb-3">
                                <Label for="email" className="col-form-label">
                                    Email:
                                </Label>
                                <Input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email || ""}
                                />
                                {formik.touched.email && formik.errors.email &&
                                    <div className="text-danger">{formik.errors.email}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            <CropImage cropOpen={cropOpen} setCropOpen={setCropOpen} image={image} setImage={setImage} />
        </React.Fragment>
        </Modal>
    )
}

export default EditUser